<template>
  <div class="account-layout">
    <div v-if="isLogged" class="account-layout__header">
      <UserInfo />
    </div>
    <div class="account-layout__content account">
      <div
        v-if="
          isLogged &&
          (!currentUser?.is_verify_phone || !currentUser?.is_verify_email || !accountStore.userBanks?.length)
        "
        class="menu-account__verify-account"
      >
        <div class="text-content">Xác minh tài khoản để rút tiền nhanh</div>
        <div class="btn-verify">
          <div
            id="verify-phone"
            class="verify-btn"
            :class="{ active: currentUser?.is_verify_phone }"
            @click="goToVerifyAccount"
          >
            <BaseImg
              :src="`images/account/profile/${iconVerify?.phone || 'icon-phone'}.svg`"
              alt="icon"
              class="icon icon-verify"
            />
          </div>
          <div
            id="verify-email"
            class="verify-btn"
            :class="{ active: currentUser?.is_verify_email }"
            @click="goToVerifyAccount"
          >
            <BaseImg
              :src="`images/account/profile/${iconVerify?.email || 'icon-email'}.svg`"
              alt="icon"
              class="icon icon-verify"
            />
          </div>
          <div
            id="verify-profile"
            class="verify-btn"
            :class="{ active: accountStore.userBanks?.length }"
            @click="goToAddBank"
          >
            <BaseImg
              :src="`images/account/profile/${iconVerify?.bank || 'icon-bank'}.svg`"
              alt="icon"
              class="icon icon-verify"
            />
          </div>
        </div>
      </div>
      <div class="section-button">
        <BaseButton
          v-if="isLogged"
          id="deposit-btn-right-menu"
          class="btn btn-deposit"
          theme="secondary"
          @click="clickDeposit()"
        >
          <BaseImg src="/assets/images/components/desktop/icon-deposit.svg" alt="deposit" />
          Nạp Tiền
        </BaseButton>
        <BaseButton
          v-if="isLogged"
          id="withdraw-btn-right-menu"
          class="btn btn-withdraw"
          theme="primary"
          @click="clickWithdraw()"
        >
          <BaseImg src="/assets/images/components/desktop/icon-withdraw.svg" alt="withdraw" />
          Rút Tiền
        </BaseButton>
      </div>
      <div v-if="isLogged" class="account-layout__nav">
        <div
          v-for="(item, index) in USER_MENU_MOBILE"
          :id="item.name"
          :key="index"
          class="nav-item"
          :class="{ active: item.activeUrl.includes(route.path) }"
          @click="handleClickNavItem(item)"
        >
          <BaseImg v-if="item.iconUrl" :src="item.iconUrl" alt="icon" class="icon-image" />
          <span v-else class="icon" :class="item.icon" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div v-if="isLogged" class="line" />
      <div v-if="isLogged" class="btn-logout" @click="onLogout">
        <span id="logout-btn" class="icon icon-logout" />
        <div class="text">Đăng xuất</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserInfo from '~/components/mobile/common/page-account/user-info.vue'
import BaseImg from '~/components/common/base-img.vue'
import BaseButton from '~/components/common/base-button.vue'
import { useLogout } from '~/composables/user/useLogout'
import { PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { useAppStore } from '~/store/app'
import { USER_MENU_MOBILE } from '~/constants/menu'
import { useAccountStore } from '@/store/account'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuMobile } from '~/types/menu.type'
const { logout } = useLogout()
const { $pinia, $openLiveChat } = useNuxtApp()
const store = useAppStore($pinia)
const accountStore = useAccountStore($pinia)
const { toggleMenuAccount, toggleMenuDeposit, toggleMenuWithdraw } = useToggleMenu()
const router = useRouter()
const route = useRoute()
const iconVerify = ref({
  phone: '',
  email: '',
  bank: ''
})
const onLogout = async () => {
  await logout()
  toggleMenuAccount()
  router.push({ path: PAGE_URLS.HOME })
}
const isLogged = computed(() => {
  return store.currentUser
})
const currentUser = computed(() => {
  return store.currentUser
})
const checkVerify = () => {
  currentUser.value?.is_verify_phone
    ? (iconVerify.value.phone = 'icon-active-phone')
    : (iconVerify.value.phone = 'icon-phone')
  currentUser.value?.is_verify_email
    ? (iconVerify.value.email = 'icon-active-email')
    : (iconVerify.value.email = 'icon-email')
  accountStore.userBanks?.length > 0
    ? (iconVerify.value.bank = 'icon-active-bank')
    : (iconVerify.value.bank = 'icon-bank')
}
watch(currentUser, () => {
  checkVerify()
})
watch(accountStore, () => {
  checkVerify()
})
onMounted(() => {
  checkVerify()
  const el = document.getElementsByClassName('modal-backdrop')?.[0]
  if (el) {
    window.location.reload()
    el?.remove()
  }
})

const clickDeposit = () => {
  store.setDepositFrom('deposit')
  toggleMenuDeposit()
  toggleMenuAccount()
}
const clickWithdraw = () => {
  toggleMenuWithdraw()
  toggleMenuAccount()
  store.setOpenWithdrawFrom('account')
}
const goToVerifyAccount = () => {
  router.push({ path: ACCOUNT_URLS.PROFILE })
  toggleMenuAccount()
}

const goToAddBank = () => {
  router.push({ path: ACCOUNT_URLS.BANK })
  toggleMenuAccount()
}
const handleClickNavItem = (item: IMenuMobile) => {
  if (item?.isLiveChat) {
    $openLiveChat()
    return
  }
  router.push(item.url)
  toggleMenuAccount()
}
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/common/page-account/index.scss" />
